import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { NavController } from '@ionic/angular'


@Injectable({
  providedIn: 'root'
})
export default class ApiService {
  private _storage: Storage | null = null;
  token
  config
  uploadConfig
  constructor(private storage: Storage, private navCtrl: NavController) {
    this.init();        
  }   
  
 async options (auth){
  if (auth) {
    this.config = {
      api: 'https://globalgalaxy.co/api',
      options: {
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+await this.get('token')
         },
      },
    } 
  }else {
    this.config = {
      api: 'https://globalgalaxy.co/api',
      options: {
        headers: { 
          'Content-Type': 'application/json',
         },
      },
    } 
  }
 }
  

   
 async UploadOptions (auth){
  if (auth) {
    this.uploadConfig = {
      api: 'https://globalgalaxy.co/api',
      options: {
        headers: { 

          'Authorization': 'Bearer '+await this.get('token')
         },
      },
    } 
  }else {
    this.uploadConfig = {
      api: 'https://globalgalaxy.co/api',
      options: {
        headers: { 

         },
      },
    } 
  }
 }

  async init() {
    const storage = await this.storage.create();
    this._storage = storage;
  }
  async set(key: string, value: any) {
   await this._storage?.set(key, value);
  }

  async get(key: string) {
   return await this._storage?.get(key);
  }

     handleResponse (response)  {
    if (response.status === 200) {
      return response.json();
    }    if (response.status === 400 || response.status === 401) {
      response.json().then((err) => {
        let error = `${err.error.message} \n
        ${err.error.details?.errors?.length!=1?err.error.details.errors.map(e=> `${e.message} `):''}
        `          
        alert(error)
      })
    } if(response.status===403){
      alert('permission denied ')
    }
    
    else {
      throw Error(response.json()?response.json(): 'error');
    }
  };

  async httpGet (endpoint,auth)  {
    await this.options(auth)

    return fetch(`${this.config.api}${endpoint}`, {
      ...this.config.options,
    })
      .then((response) => this.handleResponse(response))
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        throw Error(error);
      });
  };


    async httpPost (endpoint, data,auth){
      await this.options(auth)
    return fetch(`${this.config.api}${endpoint}`, {
      method: 'post',
      body: data ? JSON.stringify(data) : null,
      ...this.config.options,
    })
      .then((response) => this.handleResponse(response))
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        throw Error(error);
      });
  };

    
  public httpPut (endpoint, data,auth){
    return fetch(`${this.config.api}${endpoint}`, {
      method: 'put',
      body: data ? JSON.stringify(data) : null,
      ...this.config.options,
    })
      .then((response) => this.handleResponse(response))
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        throw Error(error);
      });
  };
  
    public httpDelete  (endpoint, data,auth) {
    return fetch(`${this.config.api}${endpoint}`, {
      method: 'delete',
      ...this.config.options,
    })
      .then((response) => this.handleResponse(response))
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        throw Error(error);
      });
  };

  async login(data){

    let result = await this.httpPost('/auth/local',data,false)
    if (result) {
      await this.set('token',result.jwt)
      let user = await this.httpGet('/users/me?populate=*',true)
      this.set('user',user)
      this.navCtrl.navigateRoot(['./home']);
    }
  }
  async getUser(){
    let user = await this.storage.get("user")
    return user
  }
  async getUserAuth(){
    let user = await this.storage.get("user") 
    console.log('user',user);
    
    return user.role.name
  }
  async checkLogin(){
    let token =await await this.storage.get("token")
    
    
    if (token) {
      let user = await this.httpGet('/users/me?populate=*',true)
      if (user) {
        this.navCtrl.navigateRoot(['./home']);
      }else{
        return false
      }
    }
  }
  async logout(){
    await this.storage.clear()
     this.navCtrl.navigateRoot(['./']);

  }


  async uplod(data,auth){
    await this.UploadOptions(auth)
    const formData = new FormData();
    console.log(data);
    
    formData.append('files', data);
    console.log('form',formData);
    
    return fetch(`${this.uploadConfig.api}/upload`, {
      method: 'post',
      body: formData,
      ...this.uploadConfig.options,
    })
      .then((response) => this.handleResponse(response))
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        throw Error(error);
      });
  }
}

